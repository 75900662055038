import { useState, useEffect } from "react";
import Image from "next/image";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

import Link from "next/link";
import dynamic from "next/dynamic";

// Auth 
import { useAuthenticator } from "@aws-amplify/ui-react";
import Login from '../pages/login.js'

// Components
const SignInMenu = dynamic(() => import("./SignInMenu"));
const DarkModeSwitch = dynamic(() => import("./DarkModeSwitch"));
const SearchTool = dynamic(() => import("./SearchTool"));

// Icons
import MenuIcon from "@mui/icons-material/Menu";

const pages = [
  { label: "Home", href: "/" },
  { label: "Add My Car", href: "/add-my-car" },
  { label: "Blog", href: "/blog" },
  { label: "About Us", href: "/about-us" },
];

// const settings = ["Profile", "Account", "Dashboard", "Logout"];

const ResponsiveAppBar = (props) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [prefetchLinks, setPrefetchLinks] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (user !== undefined) {
      const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
      if (groups && groups.includes("admin")) {
        setIsAdmin(true);
        pages.push({ label: "🛠️ Admin associate parts", href: "/add-a-car" },)
      }
    }
  }, [user]);

  const LoginComponent = () => {
    if (showLogin) {
      return (
        <Box sx={{ zIndex: 10 }}>
          <Login />
        </Box>
      )
    } else {
      return (
        <>
        </>
      )
    }
  }

  const handleSetShowLogin = () => {
    console.log("test show login")
    setShowLogin(!showLogin);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  let logo = props.isDarkTheme ? "/images/logo_dark.192.webp" : "/images/logo.192.webp";

  return (
    <AppBar className="AppBar" id="AppBar" position="static" sx={{ padding: "8px 0" }}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Grid item spacing={0} xs={12}>
          <Box maxWidth="xl">
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <Box
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ marginRight: 2, display: { xs: "none", md: "flex", paddingTop: "3px" } }}
                >
                  {/* Consider moving this to the left of the search box and links instead of top */}
                  <Link style={{ textDecoration: "none", color: "unset" }} prefetch={prefetchLinks} href="/" passHref>
                    {/* Warp in div in order to fix no href pass bug https://github.com/vercel/next.js/issues/7915 */}
                    <div>
                      <Image alt="Exhaust Search Logo" src={logo} quality={100} height={160/2} width={160/2} unoptimized/>
                    </div>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                    {pages.map(({ label, href }) => (
                      <Link style={{ textDecoration: "none", color: "unset" }} key={label} href={href} prefetch={prefetchLinks} passHref>
                        <Button key={label} onClick={handleCloseNavMenu} sx={{ my: 2, color: "unset", display: "block" }}>
                          {label}
                        </Button>
                      </Link>
                    ))}
                    <DarkModeSwitch handleThemeChange={props.handleThemeChange} />
                    <SignInMenu handleSetShowLogin={handleSetShowLogin} />
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ position: "absolute", right: "5px", flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {pages.map(({ label, href }) => (
                      <Link key={label} style={{ textDecoration: "none", color: "unset" }} href={href} prefetch={prefetchLinks} passHref>
                        <MenuItem key={label} onClick={handleCloseNavMenu}>
                          <Typography textAlign="center">{label}</Typography>
                        </MenuItem>
                      </Link>
                    ))}
                    <MenuItem key={"darkMode"} onClick={handleCloseNavMenu}>
                      <DarkModeSwitch handleThemeChange={props.handleThemeChange} />
                    </MenuItem>
                    <MenuItem key={"signin"}>
                      <SignInMenu handleSetShowLogin={handleSetShowLogin} />
                    </MenuItem>
                  </Menu>
                </Box>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, marginTop: "1vh", display: { xs: "flex", md: "none" } }}>
                  <Link style={{ textDecoration: "none", color: "unset" }} href="/" prefetch={prefetchLinks} passHref>
                    {/* Warp in div in order to fix no href pass bug https://github.com/vercel/next.js/issues/7915 */}
                    <div>
                      <Image alt="Exhaust Search Logo" src={logo} quality={100} height={160/2} width={160/2} unoptimized />
                      {/* <img alt="Exhaust Search Logo" src={logo} height={300} width={214} /> */}
                    </div>
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Container maxWidth="{false}" sx={{ padding: "5px" }}>
            <SearchTool />
          </Container>
        </Grid>
      </Grid>
      <LoginComponent />
    </AppBar>
  );
};
export default ResponsiveAppBar;
